// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

$fa-font-path:        "../webfonts";
@import "../../../fontawesome-pro-6.0.0-web/scss/fontawesome.scss";
@import "../../../fontawesome-pro-6.0.0-web/scss/solid.scss";
@import "../../../fontawesome-pro-6.0.0-web/scss/regular.scss";
@import "../../../fontawesome-pro-6.0.0-web/scss/brands.scss";
@import "../../../fontawesome-pro-6.0.0-web/scss/light.scss";
@import "../../../fontawesome-pro-6.0.0-web/scss/duotone.scss";
@import "../../../fontawesome-pro-6.0.0-web/scss/thin.scss";


.news_letter_mailsociete {
  display: none;
}

// pour hellosign
@media screen and (max-width: 768px) {
  .ordi1 {
    height: 170px !important;
    left: calc(50% - 155px) !important;
  }
  .bloc_bleu {
    width: calc(50% - 30px) !important;
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 1030px) {
  .mobile-height-130 {
    height: 130px !important;
  }
}

@media screen and (max-width: 990px) {
  .ul-full-width {
    width: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  .res800-full-width {
    width: 100% !important;
  }
  .img_header {
    height: 300px !important;
  }
}

@media screen and (max-width: 880px) {
  .break_table {
    display: block !important;
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    /*min-height: 300px;*/
    border-spacing: 0px !important;
    height: auto !important;
  }
  .margin-top-10 {
    margin-top: 10px!important;
  }
  .full_width-34 {
    width: calc(100% - 34px) !important;
  }
  .margintop-30 {
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 425px) {
  .ordi1 {
    height: 170px !important;
    left: calc(50% - 155px) !important;
  }
  div.content .bloc_bleu {
    width: calc(100% - 20px) !important;
    margin-bottom: 50px !important;
  }
  .no_flex {
    display: block !important;
    float: none !important;
  }
  .full_width {
    width: 100% !important;
  }
  .no_margin {
    margin: 0 !important;
  }
  .full_width-34 {
    width: calc(100% - 34px) !important;
  }
  .full_width-4 {
    width: calc(100% - 4px) !important;
    margin-top: 30px;
  }
  .img_header {
    height: 300px !important;
  }
  .text-center {
    text-align: center !important;
  }
  .mobile-auto-height {
    height: auto !important;
  }
}

.video-responsive {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}

.video-responsive iframe {
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

//

.font-smaller {
  font-size: 85%
}

.bg-gray-100{
  background: $gray-100;
}
.breadcrumb-alloc {
  position: relative;
  display: none;
  margin-bottom: 9px;
  text-align: left;
  padding: 1.5rem;
  background-color: rgba(255, 89, 80, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.arrondi {
  border-radius: 5px;


}

.bc-item {
  display: none;
  position: relative;

}

.breadcrumb-alloc-item {
  display: inline-block;
  color: rgb(151, 72, 110);
  padding-top: 12px;
}


.breadcrumb-alloc-separator {
  position: relative;
  color: black;
  left: 15px;

  &::before {
    content: '';
    background: #bb433a;
    width: 2px;
    height: 55px;
    position: absolute;
    top: 0;
    left: 0;
  }

}


.principale {
  position: relative;
  margin-bottom: 9px;
  text-align: center;
  padding: 1.5rem;
  background-color: rgba(255, 89, 80, 0.2);
  transition: all 2s ease;

  &:hover {
    background-color: rgba(255, 89, 80, 0.1);
  }
}


.secondaire {
  display: none;
  position: relative;
  text-align: center;
  background-color: rgba(255, 89, 80, 0.2);
  padding: 1.5rem;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;

  &::before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: red transparent transparent transparent;

    &:hover {
      background-color: rgba(255, 89, 80, 0.1);
    }
  }

}

h4 {
  display: inline-block;
  margin-bottom: 1rem;
}

h6 {
  font-size: 90%;
}

.btn-circle {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  background: #ff5950;
  transition: all 0.5s ease-out;
  margin-bottom: 10px;

  i {
    position: relative;
    top: -1px;
    color: white;
  }

  &:hover {
    background: #dc483f;
  }
}


.btn-circle-txt {
  display: block;
  position: absolute;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  top: 0;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
  margin-bottom: 2.25rem
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

/*------------------------------------
  Step
------------------------------------*/
.step {
  position: relative;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.step.step-dashed .step-icon::after {
  border-left-style: dashed;
}

.step-title {
  display: block;
  color: #1e2022;
  font-weight: 600;
}

.step-text:last-child {
  color: #677788;
  margin-bottom: 0;
}

.step-border-last-0 .step-item:last-child .step-icon::after {
  display: none;
}

.step .step-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1.5rem;
}

.step-item-between .step-item:last-child {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.step .step-content-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.step .step-content {
  -ms-flex: 1;
  flex: 1;
}

.step-item.collapse:not(.show) {
  display: none;
}

.step-item .step-title-description {
  display: none;
}

.step-item.focus .step-title-description {
  display: block;
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step .step-avatar {
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step .step-avatar-img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.step .step-avatar::after {
  position: absolute;
  top: 3.59375rem;
  left: 1.5625rem;
  height: calc(100% - 2.65625rem);
  border-left: 0.125rem solid #e7eaf3;
  content: "";
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step-avatar-xs .step-avatar, .step-avatar-xs.step-avatar {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.step-avatar-xs .step-avatar::after, .step-avatar-xs.step-avatar::after {
  top: 2.21875rem;
  left: 0.8125rem;
  width: 1.0625rem;
  height: calc(100% - 1.28125rem);
}

.step-avatar-xs .step-divider::after {
  left: 0.875rem;
}

.step-avatar-sm .step-avatar, .step-avatar-sm.step-avatar {
  font-size: 0.8125rem;
  width: 2.6875rem;
  height: 2.6875rem;
}

.step-avatar-sm .step-avatar::after, .step-avatar-sm.step-avatar::after {
  top: 3.15625rem;
  left: 1.28125rem;
  width: 1.0625rem;
  height: calc(100% - 2.21875rem);
}

.step-avatar-sm .step-divider::after {
  left: 1.34375rem;
}

.step-avatar-lg .step-avatar, .step-avatar-lg.step-avatar {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

.step-avatar-lg .step-avatar::after, .step-avatar-lg.step-avatar::after {
  top: 4.71875rem;
  left: 2.0625rem;
  width: 1.0625rem;
  height: calc(100% - 3.78125rem);
}

.step-avatar-lg .step-divider::after {
  left: 2.125rem;
}

/*------------------------------------
  Step Divider
------------------------------------*/
.step-divider {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  height: 1rem;
  font-weight: 600;
}

.step-divider::after {
  position: absolute;
  top: 1.46875rem;
  left: 1.5625rem;
  height: calc(100% - 0.53125rem);
  border-left: 0.125rem solid #e7eaf3;
  content: "";
}

/*------------------------------------
  Step Icon
------------------------------------*/
.step .step-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step .step-icon::after {
  position: absolute;
  top: 3.59375rem;
  left: 1.5625rem;
  height: calc(100% - 2.65625rem);
  border-left: 0.125rem solid #e7eaf3;
  content: "";
}

.step .step-icon-pseudo::before {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #97a4af;
  border-radius: 50%;
  content: "";
}

/*------------------------------------
  Step Icon Sizes
------------------------------------*/
.step-icon-xs .step-icon, .step-icon-xs.step-icon {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.step-icon-xs .step-icon::after, .step-icon-xs.step-icon::after {
  top: 2.21875rem;
  left: 0.8125rem;
  width: 1.0625rem;
  height: calc(100% - 1.28125rem);
}

.step-icon-xs .step-divider::after {
  left: 0.875rem;
}

.step-icon-sm .step-icon, .step-icon-sm.step-icon {
  font-size: 0.8125rem;
  width: 2.6875rem;
  height: 2.6875rem;
}

.step-icon-sm .step-icon::after, .step-icon-sm.step-icon::after {
  top: 3.15625rem;
  left: 1.28125rem;
  width: 1.0625rem;
  height: calc(100% - 2.21875rem);
}

.step-icon-sm .step-divider::after {
  left: 1.34375rem;
}

.step-icon-lg .step-icon, .step-icon-lg.step-icon {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

.step-icon-lg .step-icon::after, .step-icon-lg.step-icon::after {
  top: 4.71875rem;
  left: 2.0625rem;
  width: 1.0625rem;
  height: calc(100% - 3.78125rem);
}

.step-icon-lg .step-divider::after {
  left: 2.125rem;
}

/*------------------------------------
  Step Breakpoints
------------------------------------*/
@media (min-width: 400px) {
  .step-sm.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-sm .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-sm:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-sm .step-icon {
    margin-bottom: 1rem;
  }
  .step-sm .step-icon::after {
    top: 1.5625rem;
    left: 4.0625rem;
    width: calc(100% - 4.0625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-sm.step-icon-xs .step-icon::after,
  .step-sm .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 2.6875rem;
    width: calc(100% - 2.6875rem);
  }
  .step-sm.step-icon-sm .step-icon::after,
  .step-sm .step-icon.step-icon-sm::after {
    top: 1.34375rem;
    left: 3.625rem;
    width: calc(100% - 3.625rem);
  }
  .step-sm.step-icon-lg .step-icon::after,
  .step-sm .step-icon.step-icon-lg::after {
    top: 2.125rem;
    left: 5.1875rem;
    width: calc(100% - 5.1875rem);
  }
}

@media (min-width: 768px) {
  .step-md.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-md .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-md:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-md .step-icon {
    margin-bottom: 1rem;
  }
  .step-md .step-icon::after {
    top: 1.5625rem;
    left: 4.0625rem;
    width: calc(100% - 4.0625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-md.step-icon-xs .step-icon::after,
  .step-md .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 2.6875rem;
    width: calc(100% - 2.6875rem);
  }
  .step-md.step-icon-sm .step-icon::after,
  .step-md .step-icon.step-icon-sm::after {
    top: 1.34375rem;
    left: 3.625rem;
    width: calc(100% - 3.625rem);
  }
  .step-md.step-icon-lg .step-icon::after,
  .step-md .step-icon.step-icon-lg::after {
    top: 2.125rem;
    left: 5.1875rem;
    width: calc(100% - 5.1875rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-lg .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-lg:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-lg .step-icon {
    margin-bottom: 1rem;
  }
  .step-lg .step-icon::after {
    top: 1.5625rem;
    left: 4.0625rem;
    width: calc(100% - 4.0625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-lg.step-icon-xs .step-icon::after,
  .step-lg .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 2.6875rem;
    width: calc(100% - 2.6875rem);
  }
  .step-lg.step-icon-sm .step-icon::after,
  .step-lg .step-icon.step-icon-sm::after {
    top: 1.34375rem;
    left: 3.625rem;
    width: calc(100% - 3.625rem);
  }
  .step-lg.step-icon-lg .step-icon::after,
  .step-lg .step-icon.step-icon-lg::after {
    top: 2.125rem;
    left: 5.1875rem;
    width: calc(100% - 5.1875rem);
  }
}

@media (min-width: 1200px) {
  .step-xl.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-xl .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-xl:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-xl .step-icon {
    margin-bottom: 1rem;
  }
  .step-xl .step-icon::after {
    top: 1.5625rem;
    left: 4.0625rem;
    width: calc(100% - 4.0625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-xl.step-icon-xs .step-icon::after,
  .step-xl .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 2.6875rem;
    width: calc(100% - 2.6875rem);
  }
  .step-xl.step-icon-sm .step-icon::after,
  .step-xl .step-icon.step-icon-sm::after {
    top: 1.34375rem;
    left: 3.625rem;
    width: calc(100% - 3.625rem);
  }
  .step-xl.step-icon-lg .step-icon::after,
  .step-xl .step-icon.step-icon-lg::after {
    top: 2.125rem;
    left: 5.1875rem;
    width: calc(100% - 5.1875rem);
  }
}

/*------------------------------------
  Step Centered
------------------------------------*/
@media (min-width: 400px) {
  .step-sm.step-centered {
    text-align: center;
  }
  .step-sm.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-sm.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-sm.step-centered .step-icon::after {
    width: calc(100% - 3.125rem);
    left: calc(50% + 2.5rem);
  }
  .step-sm.step-centered.step-icon-xs .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.75rem);
    left: calc(50% + 1.8125rem);
  }
  .step-sm.step-centered.step-icon-sm .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.6875rem);
    left: calc(50% + 2.28125rem);
  }
  .step-sm.step-centered.step-icon-lg .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 4.25rem);
    left: calc(50% + 3.0625rem);
  }
}

@media (min-width: 768px) {
  .step-md.step-centered {
    text-align: center;
  }
  .step-md.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-md.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-md.step-centered .step-icon::after {
    width: calc(100% - 3.125rem);
    left: calc(50% + 2.5rem);
  }
  .step-md.step-centered.step-icon-xs .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.75rem);
    left: calc(50% + 1.8125rem);
  }
  .step-md.step-centered.step-icon-sm .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.6875rem);
    left: calc(50% + 2.28125rem);
  }
  .step-md.step-centered.step-icon-lg .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 4.25rem);
    left: calc(50% + 3.0625rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 3.125rem);
    left: calc(50% + 2.5rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.75rem);
    left: calc(50% + 1.8125rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.6875rem);
    left: calc(50% + 2.28125rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 4.25rem);
    left: calc(50% + 3.0625rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 3.125rem);
    left: calc(50% + 2.5rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 1.75rem);
    left: calc(50% + 1.8125rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 2.6875rem);
    left: calc(50% + 2.28125rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 4.25rem);
    left: calc(50% + 3.0625rem);
  }
}

/*------------------------------------
  Step States
------------------------------------*/
.step .step-is-valid-icon,
.step .step-is-invalid-icon {
  display: none;
}

.step .active .step-icon,
.step .active.is-valid .step-icon {
  color: #fff;
  background-color: #377dff;
}

.step .active .step-title,
.step .active.is-valid .step-title {
  color: #377dff;
}

.step .is-valid .step-icon {
  color: #fff;
  background-color: #377dff;
}

.step .is-valid .step-title {
  color: #377dff;
}

.step .is-valid .step-is-valid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
  display: none;
}

.step .is-invalid .step-icon {
  color: #fff;
  background-color: #ed4c78;
}

.step .is-invalid .step-title {
  color: #ed4c78;
}

.step .is-invalid .step-is-invalid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
  display: none;
}

/*------------------------------------
  Step Colors
------------------------------------*/
.step-icon-primary {
  color: #fff;
  background-color: #377dff;
}

.step-icon-primary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-secondary {
  color: #fff;
  background-color: #71869d;
}

.step-icon-secondary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-success {
  color: #fff;
  background-color: #00c9a7;
}

.step-icon-success.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-info {
  color: #fff;
  background-color: #09a5be;
}

.step-icon-info.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-warning {
  color: #1e2022;
  background-color: #f5ca99;
}

.step-icon-warning.step-icon-pseudo::before {
  background-color: #1e2022;
}

.step-icon-danger {
  color: #fff;
  background-color: #ed4c78;
}

.step-icon-danger.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-light {
  color: #1e2022;
  background-color: #f7faff;
}

.step-icon-light.step-icon-pseudo::before {
  background-color: #1e2022;
}

.step-icon-dark {
  color: #fff;
  background-color: #21325b;
}

.step-icon-dark.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-indigo {
  color: #fff;
  background-color: #2d1582;
}

.step-icon-indigo.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.step-icon-soft-primary.step-icon-pseudo::before {
  background-color: #377dff;
}

.step-icon-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.step-icon-soft-secondary.step-icon-pseudo::before {
  background-color: #71869d;
}

.step-icon-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.step-icon-soft-success.step-icon-pseudo::before {
  background-color: #00c9a7;
}

.step-icon-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.step-icon-soft-info.step-icon-pseudo::before {
  background-color: #09a5be;
}

.step-icon-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.step-icon-soft-warning.step-icon-pseudo::before {
  background-color: #f5ca99;
}

.step-icon-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.step-icon-soft-danger.step-icon-pseudo::before {
  background-color: #ed4c78;
}

.step-icon-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.step-icon-soft-light.step-icon-pseudo::before {
  background-color: #f7faff;
}

.step-icon-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.step-icon-soft-dark.step-icon-pseudo::before {
  background-color: #21325b;
}

.step-icon-soft-indigo {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
}

.step-icon-soft-indigo.step-icon-pseudo::before {
  background-color: #2d1582;
}

/*------------------------------------
  Step Inline
------------------------------------*/
.step-inline .step-content-wrapper {
  -ms-flex-align: center;
  align-items: center;
}

.step-inline .step-item:last-child .step-title::after {
  display: none;
}

.step-inline .step-title {
  display: inline-block;
}

@media (min-width: 400px) {
  .step-sm.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-sm.step-inline .step-item {
    overflow: hidden;
  }
  .step-sm.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-sm.step-inline .step-icon::after {
    display: none;
  }
  .step-sm.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: "";
  }
  .step-sm.step-inline.step-icon-xs .step-content .step-title::after,
  .step-sm.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-sm.step-inline.step-icon-sm .step-content .step-title::after,
  .step-sm.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.34375rem;
  }
  .step-sm.step-inline.step-icon-lg .step-content .step-title::after,
  .step-sm.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.125rem;
  }
}

@media (min-width: 768px) {
  .step-md.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-md.step-inline .step-item {
    overflow: hidden;
  }
  .step-md.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-md.step-inline .step-icon::after {
    display: none;
  }
  .step-md.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: "";
  }
  .step-md.step-inline.step-icon-xs .step-content .step-title::after,
  .step-md.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-md.step-inline.step-icon-sm .step-content .step-title::after,
  .step-md.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.34375rem;
  }
  .step-md.step-inline.step-icon-lg .step-content .step-title::after,
  .step-md.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.125rem;
  }
}

@media (min-width: 992px) {
  .step-lg.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-lg.step-inline .step-item {
    overflow: hidden;
  }
  .step-lg.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-lg.step-inline .step-icon::after {
    display: none;
  }
  .step-lg.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: "";
  }
  .step-lg.step-inline.step-icon-xs .step-content .step-title::after,
  .step-lg.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-lg.step-inline.step-icon-sm .step-content .step-title::after,
  .step-lg.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.34375rem;
  }
  .step-lg.step-inline.step-icon-lg .step-content .step-title::after,
  .step-lg.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.125rem;
  }
}

@media (min-width: 1200px) {
  .step-xl.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-xl.step-inline .step-item {
    overflow: hidden;
  }
  .step-xl.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-xl.step-inline .step-icon::after {
    display: none;
  }
  .step-xl.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: "";
  }
  .step-xl.step-inline.step-icon-xs .step-content .step-title::after,
  .step-xl.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-xl.step-inline.step-icon-sm .step-content .step-title::after,
  .step-xl.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.34375rem;
  }
  .step-xl.step-inline.step-icon-lg .step-content .step-title::after,
  .step-xl.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.125rem;
  }
}

/*------------------------------------
  Step Timeline
------------------------------------*/
@media (min-width: 400px) {
  .step-timeline-sm {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-sm .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.34375rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.125rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-sm .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-sm .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-sm .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-sm .step-icon-sm {
    margin-left: -1.34375rem;
  }
  .step-timeline-sm .step-icon-lg {
    margin-left: -2.125rem;
  }
}

@media (min-width: 768px) {
  .step-timeline-md {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-md .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.34375rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.125rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-md .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-md .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-md .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-md .step-icon-sm {
    margin-left: -1.34375rem;
  }
  .step-timeline-md .step-icon-lg {
    margin-left: -2.125rem;
  }
}

@media (min-width: 992px) {
  .step-timeline-lg {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-lg .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.34375rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.125rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-lg .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-lg .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-lg .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-lg .step-icon-sm {
    margin-left: -1.34375rem;
  }
  .step-timeline-lg .step-icon-lg {
    margin-left: -2.125rem;
  }
}

@media (min-width: 1200px) {
  .step-timeline-xl {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-xl .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.34375rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.125rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-xl .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-xl .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-xl .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-xl .step-icon-sm {
    margin-left: -1.34375rem;
  }
  .step-timeline-xl .step-icon-lg {
    margin-left: -2.125rem;
  }
}